<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame videobackground">
         
           <video ref="videoPlayer" autoplay class="videoPlayer">
            <source
              :src="this.videoData[$parent.videoPath][$parent.chosenLanguage]['hackervideo']"
              type="video/mp4"
            />
            <track :src="this.videoData[$parent.videoPath][$parent.chosenLanguage]['hackervideoSubs']" kind="subtitles" srclang="en" label="English" default>
          </video>
          </div>
        </div>
    </div>
  </div>
</template>

<style>
  @import '../assets/form.css';
</style>

<script>
   import axios from 'axios'
   
  export default {
    name: 'StartGame',
    props: {
      msg: String,
      dbid: String,
      videoData: Object
    },
     async mounted () {
      setTimeout(() => {  this.GameMove() }, this.videoData[this.$parent.videoPath][this.$parent.chosenLanguage]['hackervideoTime']);
     },
    data () {
     return {
      teamName: ""
    }
  },
  methods: {
    GameMove() {
        axios
          .post(process.env.VUE_APP_ip+'/updatestage', {"stage": 3, "dbid": this.dbid})
    }
  }
}
</script>


