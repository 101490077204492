
<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame">
          <div class="innerframe">
            <div v-html="$parent.language.socialQuestions.body"></div>

            <div class="question" v-bind:class="{ green: question1ok, red: question1wrong }">
              <div class="row btnwidth rowlabel">
                <div v-html="$parent.language.socialQuestions.question1"></div>
              </div>
              <div class="row btnwidth rowlabel socialquestion">

                <div class="field">
                  <input class="input" placeholder="" v-model="question1" />
                </div>
                <div class="field field--button ">
                  <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
                    @click="checkQuestion(1)">{{ $parent.language.socialQuestions.check }}</button>
                </div>
              </div>
            </div>
            <div class="question" v-bind:class="{ green: question2ok, red: question2wrong }">
              <div class="row btnwidth rowlabel">
                <div v-html="$parent.language.socialQuestions.question2"></div>
              </div>
              <div class="row btnwidth rowlabel socialquestion">

                <div class="field">
                  <input class="input" placeholder="" v-model="question2" />
                </div>
                <div class="field field--button">
                  <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
                    @click="checkQuestion(2)">{{ $parent.language.socialQuestions.check }}</button>
                </div>
              </div>
            </div>

            <div class="question" v-bind:class="{ green: question3ok, red: question3wrong }">
              <div class="row btnwidth rowlabel socialquestion">
                <div v-html="$parent.language.socialQuestions.question3"></div>
              </div>
              <div class="row btnwidth rowlabel">

                <div class="field">
                  <input class="input" placeholder="" v-model="question3" />
                </div>
                <div class="field field--button">
                  <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
                    @click="checkQuestion(3)">{{ $parent.language.socialQuestions.check }}</button>
                </div>
              </div>
            </div>

            <div class="question" v-bind:class="{ green: question4ok, red: question4wrong }">
              <div class="row btnwidth rowlabel">
                <div v-html="$parent.language.socialQuestions.question4"></div>
              </div>
              <div class="row btnwidth rowlabel">

                <div class="field">
                  <input class="input" placeholder="" v-model="question4" />
                </div>
                <div class="field field--button">
                  <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
                    @click="checkQuestion(4)">{{ $parent.language.socialQuestions.check }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';
</style>

<script>
import axios from 'axios'

export default {
  name: 'StartGame',
  props: {
    msg: String,
    dbid: String
  },
  async mounted() {
    this.$emit('pauseTimer', 0)
  },
  data() {
    return {
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question1ok: "",
      question1wrong: "",
      question2ok: "",
      question2wrong: "",
      question3ok: "",
      question3wrong: "",
      question4ok: "",
      question4wrong: ""
    }
  },
  methods: {
    checkQuestion(number) {
      if (number == 1) {
        this.question1ok = ""
        this.question1wrong = ""
        if (this.question1.toLowerCase() == this.$parent.language.socialQuestions.answer1) {
          this.question1ok = true
        } else {
          this.question1wrong = true
        }
      }

      if (number == 2) {
        this.question2ok = ""
        this.question2wrong = ""
        if (this.question2.toLowerCase() == this.$parent.language.socialQuestions.answer2 || this.question2.toLowerCase() == this.$parent.language.socialQuestions.answer21) {
          this.question2ok = true
        } else {
          this.question2wrong = true
        }
      }

      if (number == 3) {
        this.question3ok = ""
        this.question3wrong = ""
        if (this.question3.toLowerCase() == this.$parent.language.socialQuestions.answer3 || this.question3.toLowerCase() == this.$parent.language.socialQuestions.answer31 || this.question3.toLowerCase() == this.$parent.language.socialQuestions.answer33) {
          this.question3ok = true
        } else {
          this.question3wrong = true
        }
      }
      if (number == 4) {
        this.question4ok = ""
        this.question4wrong = ""
        console.log(this.question4)
        if (this.question4 == this.$parent.language.socialQuestions.answer4) {
          this.question4ok = true
        } else {
          this.question4wrong = true
        }
      }

      if (this.question1ok == true && this.question2ok == true && this.question3ok == true && this.question4ok == true) {
        axios
          .post(process.env.VUE_APP_ip + '/updatestage', { "stage": 9, "dbid": this.dbid })
      }

    },
  }
}
</script>


