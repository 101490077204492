<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame">
          <div class="innerframe">
            <div v-html="$parent.language.TextView.body"></div>

            <div class="field field--button btnwidth">
              <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
                @click="StartGame()">{{ $parent.language.TextView.button }}</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';
</style>

<script>
import axios from 'axios'

export default {
  name: 'StartGame',
  props: {
    msg: String,
    dbid: String
  },
  data() {
    return {
      teamName: ""
    }
  },
  methods: {
    async StartGame() {
      await axios
        .post(process.env.VUE_APP_ip + '/updatestage', { "stage": 2, "dbid": this.dbid })
    }
  }
}
</script>


