
export async function setup() {

    const logoData = []
        //Default English


        logoData['XL'] = require("../assets/logo.png")
        logoData['demoLogo'] = require("../assets/demologo.png")



      return logoData

    }



