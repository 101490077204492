<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame videobackground">
           <video ref="videoPlayer" autoplay class="videoPlayer">
            <source
              :src="this.videoData[$parent.videoPath][$parent.chosenLanguage]['messagesVideo']"
              type="video/mp4"
            />
              <track :src="this.videoData[$parent.videoPath][$parent.chosenLanguage]['messagesVideoSubs']" kind="subtitles" srclang="en" label="English" default>
          </video>
          </div>
        </div>
    </div>
  </div>
</template>

<style>
  @import '../assets/form.css';
</style>

<script>
   import axios from 'axios'
   
  export default {
    name: 'messagesVideo',
    props: {
      msg: String,
       dbid: String,
        videoData: Object
    },
     async mounted () {

      this.$emit('pauseTimer', 1)

      setTimeout(() => {  this.GameMove() },  this.videoData[this.$parent.videoPath][this.$parent.chosenLanguage]['messagesVideoTime']);
     },
    data () {
     return {
      teamName: ""
    }
  },
  methods: {
    GameMove() {
         axios
         .post(process.env.VUE_APP_ip+'/updatestage', {"stage": 10, "dbid": this.dbid})
    }
  }
}
</script>


