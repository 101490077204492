
export async function setup() {

    const videoData = []
        //Default English
        videoData.XL = []
        videoData.XL.English = []

        videoData.XL.English.hackervideo = require("../assets/video/XL/English/Philip/1Philip.mp4")
        videoData.XL.English.hackervideoTime = 1 // 28000;
        videoData.XL.English.hackervideoSubs = require("../assets/video/XL/English/Ivana/bruteforce.vtt");
        videoData.XL.English.bruteVideo = require("../assets/video/XL/English/Ivana/bruteforce.mp4");
        videoData.XL.English.bruteVideoTime = 1 // 58000;
        videoData.XL.English.bruteVideoSubs = require("../assets/video/XL/English/Ivana/bruteforce.vtt");
        videoData.XL.English.encryptionVideo = require("../assets/video/XL/English/Ivana/2Greatyougotin.mp4");
        videoData.XL.English.encryptionVideoTime = 1 // 45000;
        videoData.XL.English.encryptionVideoSubs = require("../assets/video/XL/English/Ivana/2Greatyougotin.vtt");
        videoData.XL.English.socialVideo = require("../assets/video/XL/English/Ivana/3Photoalbum.mp4");
        videoData.XL.English.socialVideoTime = 1 // 29000;
        videoData.XL.English.socialVideoSubs = require("../assets/video/XL/English/Ivana/3Photoalbum.vtt");
        videoData.XL.English.messagesVideo = require("../assets/video/XL/English/Ivana/4resetaccountsafefornow.mp4");
        videoData.XL.English.smessagesVideoTime = 1 // 27000;
        videoData.XL.English.messagesVideoSubs = require("../assets/video/XL/English/Ivana/4resetaccountsafefornow.vtt");
        videoData.XL.English.finalHackerVideo = require("../assets/video/XL/English/Ivana/blockedhisaccess.mp4");
        videoData.XL.English.finalHackerVideoTime = 1 // 23000;
        videoData.XL.English.finalHackerVideoSubs = require("../assets/video/XL/English/Ivana/blockedhisaccess.vtt");

        videoData.XL.English.endGameVideo = require("../assets/video/XL/English/Ivana/YoudefeatedPhilip.mp4");
        videoData.XL.English.endGameVideoTime = 30 // 22000;
        videoData.XL.English.endGameVideoSubs = require("../assets/video/XL/English/Ivana/YoudefeatedPhilip.vtt");

        videoData.XL.English.trainingVideo = require("../assets/video/XL/English/Philip/rickroll.mp4");
        videoData.XL.English.trainingVideoTime = 204600;
        videoData.XL.English.trainingVideoSubs = require("../assets/video/XL/English/Philip/rickroll.vtt");

       // Chanel
        videoData.Chanel = []
        videoData.Chanel.French = []
        videoData.Chanel.French.hackervideo = require("../assets/video/Chanel/French/Philip/Edited_PhilipOphish_FR1.mp4")
        videoData.Chanel.French.hackervideoTime = 41000;
        videoData.Chanel.French.hackervideoSubs = require("../assets/video/Chanel/French/Philip/PhilipOphishFR1.vtt");
        videoData.Chanel.French.bruteVideo = require("../assets/video/Chanel/French/Ivana/Edited_Claire_Voyance_FR1.mp4");
        videoData.Chanel.French.bruteVideoTime = 60000;
        videoData.Chanel.French.bruteVideoSubs = require("../assets/video/Chanel/French/Ivana/FR1.vtt");
        videoData.Chanel.French.encryptionVideo = require("../assets/video/Chanel/French/Ivana/Edited_Claire_Voyance_FR2.mp4");
        videoData.Chanel.French.encryptionVideoTime = 41000 ;
        videoData.Chanel.French.encryptionVideoSubs = require("../assets/video/Chanel/French/Ivana/FR2.vtt");
        videoData.Chanel.French.socialVideo = require("../assets/video/Chanel/French/Ivana/Edited_Claire_Voyance_FR3.mp4");
        videoData.Chanel.French.socialVideoTime = 35000 ;
        videoData.Chanel.French.socialVideoSubs = require("../assets/video/Chanel/French/Ivana/FR3.vtt");
        videoData.Chanel.French.messagesVideo = require("../assets/video/Chanel/French/Ivana/Edited_Claire_Voyance_FR4.mp4");
        videoData.Chanel.French.messagesVideoTime = 33000;
        videoData.Chanel.French.messagesVideoSubs = require("../assets/video/Chanel/French/Ivana/FR4.vtt");
        videoData.Chanel.French.finalHackerVideo = require("../assets/video/Chanel/French/Ivana/Edited_Claire_Voyance_FR5.mp4");
        videoData.Chanel.French.finalHackerVideoTime = 28000;
        videoData.Chanel.French.finalHackerVideoSubs = require("../assets/video/Chanel/French/Ivana/FR5.vtt");
        videoData.Chanel.French.endGameVideo = require("../assets/video/Chanel/French/Ivana/Edited_Claire_Voyance_FR6.mp4");
        videoData.Chanel.French.endGameVideoTime = 29000 ;
        videoData.Chanel.French.endGameVideoSubs = require("../assets/video/Chanel/French/Ivana/FR6.vtt");

        videoData.Chanel.French.endGameVideoNoPrize = require("../assets/video/Chanel/French/Ivana/Edited_Claire_Voyance_FR6.mp4");
        videoData.Chanel.French.endGameVideoTimeNoPrize = 29000;
        videoData.Chanel.French.endGameVideoSubsNoPrize = require("../assets/video/Chanel/French/Ivana/FR6.vtt");
        videoData.Chanel.French.trainingVideo = require("../assets/video/Chanel/French/Philip/bthEnd.mp4");
        videoData.Chanel.French.trainingVideoTime = 226000;
        videoData.Chanel.French.trainingVideoSubs = require("../assets/video/Chanel/French/Philip/bthEnd.vtt");

        videoData.Chanel.English = []
        videoData.Chanel.English.hackervideo = require("../assets/video/Chanel/English/Philip/Edited_PhilipOphish_EN1.mp4")
        videoData.Chanel.English.hackervideoTime = 39000 // 28000;
        videoData.Chanel.English.hackervideoSubs = require("../assets/video/Chanel/English/Philip/EditedPhilipOphishEN1.vtt");
        videoData.Chanel.English.bruteVideo = require("../assets/video/Chanel/English/Ivana/IW_Ch_EN_1__AdobeExpress.mp4");
        videoData.Chanel.English.bruteVideoTime = 58000 ;
        videoData.Chanel.English.bruteVideoSubs = require("../assets/video/Chanel/English/Ivana/IWChEN1.vtt");
        videoData.Chanel.English.encryptionVideo = require("../assets/video/Chanel/English/Ivana/IW_Ch_EN_2__AdobeExpress.mp4");
        videoData.Chanel.English.encryptionVideoTime = 45000 ;
        videoData.Chanel.English.encryptionVideoSubs = require("../assets/video/Chanel/English/Ivana/IWChEN2.vtt");
        videoData.Chanel.English.socialVideo = require("../assets/video/Chanel/English/Ivana/IW_Ch_EN_3__AdobeExpress.mp4");
        videoData.Chanel.English.socialVideoTime = 39000;
        videoData.Chanel.English.socialVideoSubs = require("../assets/video/Chanel/English/Ivana/IWChEN3.vtt");
        videoData.Chanel.English.messagesVideo = require("../assets/video/Chanel/English/Ivana/IW_Ch_EN_4__AdobeExpress.mp4");
        videoData.Chanel.English.messagesVideoTime = 34000;
        videoData.Chanel.English.messagesVideoSubs = require("../assets/video/Chanel/English/Ivana/IWChEN4.vtt");
        videoData.Chanel.English.finalHackerVideo = require("../assets/video/Chanel/English/Ivana/IW_Ch_EN_5__AdobeExpress.mp4");
        videoData.Chanel.English.finalHackerVideoTime = 26000 ;
        videoData.Chanel.English.finalHackerVideoSubs = require("../assets/video/Chanel/English/Ivana/IWChEN5.vtt");

        videoData.Chanel.English.endGameVideo = require("../assets/video/Chanel/English/Ivana/IW_Ch_EN_6__AdobeExpress.mp4");
        videoData.Chanel.English.endGameVideoTime = 29000;
        videoData.Chanel.English.endGameVideoSubs = require("../assets/video/Chanel/English/Ivana/IWChEN6.vtt");

        videoData.Chanel.English.endGameVideoNoPrize = require("../assets/video/Chanel/English/Ivana/IW_Ch_EN_6__AdobeExpress.mp4");
        videoData.Chanel.English.endGameVideoTimeNoPrize = 29000;
        videoData.Chanel.English.endGameVideoSubsNoPrize = require("../assets/video/Chanel/English/Ivana/IWChEN6.vtt");

        videoData.Chanel.English.trainingVideo = require("../assets/video/Chanel/English/Philip/bthEnd.mp4");
        videoData.Chanel.English.trainingVideoTime = 226000;
        videoData.Chanel.English.trainingVideoSubs = require("../assets/video/Chanel/English/Philip/bthEnd.vtt");

        videoData.Nokia = []
        videoData.Nokia.English = []
        videoData.Nokia.English.hackervideo = require("../assets/video/Vanilla/English/Philip/Edited_PhilipOphish_EN1.mp4")
        videoData.Nokia.English.hackervideoTime = 29000 // 28000;
        videoData.Nokia.English.hackervideoSubs = require("../assets/video/Vanilla/English/Philip/EditedPhilipOphishEN1.vtt");
        videoData.Nokia.English.bruteVideo = require("../assets/video/Vanilla/English/Ivana/XLIW1.mp4");
        videoData.Nokia.English.bruteVideoTime = 48000;
        videoData.Nokia.English.bruteVideoSubs = require("../assets/video/Vanilla/English/Ivana/XLIW1.vtt");

        videoData.Nokia.English.encryptionVideo = require("../assets/video/Vanilla/English/Ivana/XLIW2.mp4");
        videoData.Nokia.English.encryptionVideoTime = 42000 ;
        videoData.Nokia.English.encryptionVideoSubs = require("../assets/video/Vanilla/English/Ivana/XLIW2.vtt");

        videoData.Nokia.English.socialVideo = require("../assets/video/Vanilla/English/Ivana/XLIW3.mp4");
        videoData.Nokia.English.socialVideoTime = 35000;
        videoData.Nokia.English.socialVideoSubs = require("../assets/video/Vanilla/English/Ivana/XLIW3.vtt");
        videoData.Nokia.English.messagesVideo = require("../assets/video/Vanilla/English/Ivana/XLIW4.mp4");
        videoData.Nokia.English.messagesVideoTime = 32000;
        videoData.Nokia.English.messagesVideoSubs = require("../assets/video/Vanilla/English/Ivana/XLIW4.vtt");
        videoData.Nokia.English.finalHackerVideo = require("../assets/video/Vanilla/English/Ivana/XLIW5.mp4");
        videoData.Nokia.English.finalHackerVideoTime = 24000 ;
        videoData.Nokia.English.finalHackerVideoSubs = require("../assets/video/Vanilla/English/Ivana/XLIW5.vtt");

        videoData.Nokia.English.endGameVideo = require("../assets/video/Vanilla/English/Ivana/XLIW6.mp4");
        videoData.Nokia.English.endGameVideoTime = 23000;
        videoData.Nokia.English.endGameVideoSubs = require("../assets/video/Vanilla/English/Ivana/XLIW6.vtt");

        videoData.Nokia.English.endGameVideoNoPrize = require("../assets/video/Vanilla/English/Ivana/XLIW6NP.mp4");
        videoData.Nokia.English.endGameVideoTimeNoPrize = 23000;
        videoData.Nokia.English.endGameVideoSubsNoPrize = require("../assets/video/Vanilla/English/Ivana/XLIW6NP.vtt");

        videoData.Nokia.English.trainingVideo = require("../assets/video/Vanilla/English/Philip/bthEnd.mp4");
        videoData.Nokia.English.trainingVideoTime = 226000;
        videoData.Nokia.English.trainingVideoSubs = require("../assets/video/Vanilla/English/Philip/bthEnd.vtt");


      return videoData

    }



