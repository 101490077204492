<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame ">
          <div class="innerframe extrapadding">
            <div v-html="$parent.language.bruteClue.body"></div>
          </div>
        <div>
          <div  v-bind:class="{ green: questionok, red: questionwrong }" class="container encpanel ">

            <div class="row">
                <div class="field encryptionLetter" hidden>
                <input class="input" value="" disabled />
              </div>
              <div class="field encryptionLetter">
               <input class="input" placeholder="D" maxlength="1" v-model="letter1" ref="firstbox"  v-on:keyup="this.$refs.secondbox.focus(); this.$refs.secondbox.select();  this.populateClue()" :disabled="this.$parent.gameSettings.bruteManual1 != '?'" />
              </div>
              <div class="field encryptionLetter">
                <input class="input" placeholder="D" maxlength="1" v-model="letter2" ref="secondbox"  v-on:keyup="this.$refs.thirdbox.focus(); this.$refs.thirdbox.select();  this.populateClue()" :disabled="this.$parent.gameSettings.bruteManual2 != '?'"/>
              </div>
              <div class="field encryptionLetter">
                <input class="input" placeholder="M" maxlength="1" v-model="letter3" ref="thirdbox"  v-on:keyup="this.$refs.fourthbox.focus(); this.$refs.fourthbox.select(); this.populateClue()" :disabled="this.$parent.gameSettings.bruteManual3 != '?'"/>
              </div>
              <div class="field encryptionLetter">
                <input class="input" placeholder="M" maxlength="1" v-model="letter4" ref="fourthbox" v-on:keyup="this.$refs.fithbox.focus(); this.$refs.fithbox.select(); this.populateClue()" :disabled="this.$parent.gameSettings.bruteManual4 != '?'"/>
              </div>
              <div class="field encryptionLetter">
                 <input class="input" placeholder="Y" maxlength="1" v-model="letter5" ref="fithbox" v-on:keyup="this.$refs.sixthbox.focus(); this.$refs.sixthbox.select();  this.populateClue()" :disabled="this.$parent.gameSettings.bruteManual5 != '?'" />
              </div>
              <div class="field encryptionLetter">
                 <input class="input" placeholder="Y" maxlength="1" v-model="letter6" ref="sixthbox" v-on:keyup="this.$refs.firstbox.focus(); this.$refs.firstbox.select(); this.populateClue()" :disabled="this.$parent.gameSettings.bruteManual6 != '?'" />
              </div>
              <div class="field field--button">
                <button class="button" type="submit" :style="{'background-color':$parent.buttonColor}" @click="checkcode()">{{$parent.language.encryption.check}}</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<style>
  @import '../assets/form.css';
</style>

<script>
  import axios from 'axios'

 export default {
  name: 'bruteVideo',
  props: {
    msg: String,
    dbid: String
  },
  async mounted () {
    this.$emit('pauseTimer', 0)
    
    this.$refs.firstbox.focus()
 //  axios
 //  .post('http://192.168.1.224:3000/updatestage', {"stage": 7})
 //  setTimeout(() => {  this.GameMove() }, 120000);

 if (this.$parent.gameSettings.bruteManual1 != "?"){
 this.letter1 = this.$parent.gameSettings.bruteManual1
}
 if (this.$parent.gameSettings.bruteManual2 != "?"){
this.letter2 = this.$parent.gameSettings.bruteManual2
}

 if (this.$parent.gameSettings.bruteManual3 != "?"){
this.letter3 = this.$parent.gameSettings.bruteManual3
}

 if (this.$parent.gameSettings.bruteManual4 != "?"){
this.letter4 = this.$parent.gameSettings.bruteManual4
}

 if (this.$parent.gameSettings.bruteManual5 != "?"){
this.letter5 = this.$parent.gameSettings.bruteManual5
}
 if (this.$parent.gameSettings.bruteManual6 != "?"){
this.letter6 = this.$parent.gameSettings.bruteManual6
}



 },
 data () {
   return {
    letter1: "",
    letter2: "",
    letter3: "",
    letter4: "",
    questionok: false,
    questionwrong: false,
    randNumber: []
  }
},
methods: {

  checkcode() {

   var clue = "" + this.letter1 + this.letter2 + this.letter3 + this.letter4 + this.letter5 + this.letter6;

   console.log(clue)

    if ( clue == this.$parent.gameSettings.brutemanualCorrect  ){
      console.log("pass")
      this.questionwrong = false
      this.questionok = true
      console.log(process.env.VUE_APP_ip)
       axios
        .post(process.env.VUE_APP_ip+'/codeboxopen', {"boxid": this.$parent.info.boxid, "dbid": this.$parent.dbid})
    } else {
      console.log("error")
      this.questionwrong = true
    }
   
      },
      populateClue(){
        for ( var number of this.randNumber){
           switch (number) {
  case 1:
    this.letter1 = this.$parent.language.encryption.clue0.toUpperCase()
    break;
  case 2:
    this.letter2 = this.$parent.language.encryption.clue1.toUpperCase()
    break;
  case 3:
    this.letter3 = this.$parent.language.encryption.clue2.toUpperCase()
    break;
  case 4:
    this.letter4 = this.$parent.language.encryption.clue3.toUpperCase()
    break;
  case 5:
    this.letter5 = this.$parent.language.encryption.clue4.toUpperCase()
    break;
  }
}
      }
    }
  }
</script>


