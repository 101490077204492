<template :style="dynamicStyle">

<template v-if=" dbid=='62816a01c0014f5adad845d5'">
  <setup @dbidfu="dbidupdate" @langup="setLanguage" @vidoePathSetup="VideoPath" @langdata="GlobalLanguageContent" @gamedata="gameSetting"/>
</template>

<template v-if="name === undefined && dbid != '62816a01c0014f5adad845d5' && language == '' ">
  <language @dbidfu="dbidupdate" @langChoise="LanguageChoice" />
</template>

  <template v-if="name === undefined && dbid != '62816a01c0014f5adad845d5' && language !== '' ">
    <StartGame :dbid="this.dbid" />
  </template>

  <template v-if="stage == 1">
   <TextView @reset="endGameReset" :dbid="this.dbid"/>
 </template>

 <template v-if="stage == 2">
   <hackerVideo  :dbid="this.dbid" :videoData="this.videoData"/>
 </template>

 <template v-if="stage == 3" >
   <bruteVideo @pauseTimer="manageTimer" :dbid="this.dbid" :videoData="this.videoData"/>
 </template>

  <template v-if="stage == 4 && this.gameSettings.type == 1" >
   <bruteClue :dbid="this.dbid" :info="this.info" @pauseTimer="manageTimer"/>
 </template>

   <template v-if="stage == 4 && this.gameSettings.type == 2">
   <bruteClueManual :dbid="this.dbid" :info="this.info" @pauseTimer="manageTimer"/>
 </template>

 <template v-if="info.codeBox !== null  && stage == 5">
   <encryptionVideo :dbid="this.dbid" @pauseTimer="manageTimer" :videoData="this.videoData"/>
 </template>

  <template  v-if="stage == 6">
<!--  v-if="stage == 6" -->
   <encryption :dbid="this.dbid" @pauseTimer="manageTimer"/>
 </template>


 <template v-if="stage == 7">
   <socialVideo :dbid="this.dbid" @pauseTimer="manageTimer" :videoData="this.videoData"/>
 </template>

 <template  v-if="stage == 8">
<!--   v-if="stage == 8" -->

   <socialQuestions :dbid="this.dbid" @pauseTimer="manageTimer"/>
 </template>

  <template v-if="stage == 9">
   <messagesVideo :dbid="this.dbid" @pauseTimer="manageTimer" :videoData="this.videoData"/>
 </template>

 
  <template v-if="stage == 10" > <!-- // && this.info.message1answer == null && this.info.message2answer == null && this.info.message3answer == null"> -->
   <messagesGame :dbid="this.dbid" :info="this.info" @pauseTimer="manageTimer" @addPenalty="addPenalty" />
 </template>

    <template v-if="stage == 11">
   <finalHacker :dbid="this.dbid"  :info="this.info" @pauseTimer="manageTimer" :videoData="this.videoData"/>
 </template>

  <template v-if="stage == 12">
   <endGame :dbid="this.dbid" :info="this.info" @pauseTimer="manageTimer" :penaltyCount="this.penaltyCount" :timer="this.timerValue" :videoData="this.videoData" />
 </template>

   <template v-if="stage == 13 && this.gameSettings.trainingVideo == 'yes'" >
   <trainingVideo :dbid="this.dbid" :info="this.info" @pauseTimer="manageTimer" :videoData="this.videoData"/>
 </template>

   <template v-if="stage == 14">
   <endScreen @reset="endGameReset" />
 </template>



 <template v-if="timer == 1">
  <Timer :pause="this.pauseTimer" :penalty="this.penalty" @updateTimer="updateTimer" @penaltyDone="penaltyDone"/>
</template>

<template v-if="stage > 0">
<cornerLogo />
</template>

</template>

<script>

 



  import axios from 'axios'
  import cornerLogo from './components/cornerLogo.vue'
  import setup from './components/setup.vue'
  import StartGame from './components/StartGame.vue'
  import TextView from './components/TextView.vue'
  import Timer from './components/timer.vue'
  import hackerVideo from './components/hackerVideo.vue'
  import bruteVideo from './components/bruteVideo.vue'
  import bruteClue from './components/bruteClue.vue'
  import bruteClueManual from './components/bruteClueManual.vue'
  import encryption from './components/encryption.vue'
  import socialVideo from './components/socialVideo.vue'
  import socialQuestions from './components/socialQuestions.vue'
  import encryptionVideo from './components/encryptionVideo.vue'
  import messagesVideo from './components/messagesVideo.vue'
  import messagesGame from './components/messagesGame.vue'
  import finalHacker from './components/finalHacker.vue'
  import endGame from './components/endGame.vue'
  import endScreen from './components/endScreen.vue'
  import trainingVideo from './components/trainingVideo.vue'
  import language from './components/language.vue'
  const  videos  = require('./plugins/video.js')
  const  logo  = require('./plugins/logo.js')

  export default {
    name: 'App',
    components: {
      cornerLogo,
      StartGame,
      TextView,
      hackerVideo,
      bruteVideo,
      Timer,
      encryption,
      socialVideo,
      socialQuestions,
      bruteClue,
      bruteClueManual,
      encryptionVideo,
      messagesVideo,
      messagesGame,
      finalHacker,
      endGame,
      endScreen,
      setup,
      language,
      trainingVideo
    },
    data () {
     return {
      info: '',
      name:'',
      startsound: false,
      stage: 0,
      timer: 0,
      pauseTimer: 0,
      timerValue: 0,
      penalty: false,
      penaltyCount: 0,
      dbid: "62816a01c0014f5adad845d5",
      language: '',
      langOptions: '',
      chosenLanguage:'',
      videoData: [],
      logoData:[],
      videoPath: 'XL',
      GlobalLanguageContentObj: {},
      gameSettings:{},
      gameBackground: "#172031",
      buttonColor:"#f29412",
      logoFile:"logo1.png"

    }
  },
  async mounted () {

   this.videoData = await videos.setup()
   this.logoData = await logo.setup()

   window.setInterval( async() => {
    var response =  await this.getNotifications()
    if (typeof response.data != "string"){
    this.info = response.data.body

    this.name = this.info.name

    this.stage = this.info.stage
  }

    if (this.stage > 2){
      this.timer = 1
    } else {
      this.timer = 0 
    }


  }, 1000)
 },
 methods: {
  endGameReset() {
    console.log("reset")
     this.info = ''
     this.name = ''
      this.startsound = false
      this.stage = 0
      this.timer = 0
      this.pauseTimer = 0
      this.timerValue = 0
      this.penalty = false
      this.penaltyCount = 0
      this.dbid = "62816a01c0014f5adad845d5"
  },
  penaltyDone(){
    this.penalty = false
    this.penaltyCount += 1
  },
  addPenalty(){
    this.penalty = true
  },
  updateTimer(value){
    this.timerValue = value

  },
  getNotifications() {
    return axios
    .post(process.env.VUE_APP_ip+'/gamedata', {"dbid": this.dbid})


  },
    manageTimer (value) {
         this.pauseTimer = value;
      },
      dbidupdate(value){
        this.dbid = value
        console.log(value)

      },
      setLanguage(value){
        this.langOptions = value
      },
      // SetupLangContent(value){
      //   this.language = this.chosenLanguage[value]

      // },
      LanguageChoice(value){
        this.chosenLanguage = value
        this.language = this.GlobalLanguageContentObj[value]
      },
      VideoPath(value){
        this.videoPath = value
      },
      GlobalLanguageContent(value){
        this.GlobalLanguageContentObj = value
      },
      gameSetting(value){
        this.gameSettings = value

        // here we can set any global chnages
        this.gameSettings.background = value.background
        document.body.style.backgroundColor = this.gameSettings.background;
        document.body.style.color = this.gameSettings.textColor;
        this.buttonColor = value.buttonColor
        this.logoFile = this.gameSettings.logoFile
        this.containerColor = this.gameSettings.containerColor


      },
  startApi(){
       window.setInterval( async() => {
    var response =  await this.getNotifications()
    this.info = response.body

    this.name = this.info.name
    this.stage = this.info.stage

    if (this.stage > 2){
      this.timer = 1
    } else {
      this.timer = 0 
    }


  }, 1000)
  }

}
}
</script>

<style>
  @import './assets//styles.css';

</style>

