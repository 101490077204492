<template>
  <img alt="Vue logo" src="../assets/logo.png" height="350">
  <div class="center">
   <p v-bind:class="{ isred: nameError}"> Enter your license number and box id to get started </p>

  <div class="container">
    <template v-if="error != ''">
      <div class="row">
        <p>{{error}}</p>
    </div>
    </template>
    <div class="row">
        <input class="input" placeholder="License Number" v-model="license" />
    </div>
    <div class="row">
        <input class="input" placeholder="Box ID" v-model="boxid" />
    </div>
      <div class="row">
        <button class="button" type="submit" @click="GameSetup()">Go</button>
    </div>
  </div>
  </div>
</template>

<style>
  @import '../assets/form.css';
</style>

<script>
  import axios from 'axios'
export default {
  name: 'setupGame',
  props: {

  },
  data () {
     return {
      teamName: "",
      nameError: false,
      license: "",
      boxid: "",
      error: ""

    }
  },
  async mounted () {
 

      if (localStorage.getItem("license") !== null && localStorage.getItem("boxid") !== null ) {
        this.license = localStorage.getItem("license")
        this.boxid = localStorage.getItem("boxid")
        await this.GameSetup()
      }
  },
   methods: {
    async GameSetup()  {
      this.error = ""
        var setup = await axios.post(process.env.VUE_APP_ip+'/gamesetup', {"license": this.license, "boxid": this.boxid })
        console.log(setup)
        if (setup.data.error){
          console.log(setup.data.error)
          this.error = setup.data.error
        } else {
          console.log("|||||")
          console.log(setup.data)
          localStorage.setItem('license', this.license); 
          localStorage.setItem('boxid', this.boxid); 
           this.$emit('dbidfu', setup.data.body.dbid)
           this.$emit('vidoePathSetup', setup.data.body.videoPath)
           this.$emit('langup', setup.data.body.language)
           this.$emit('langdata', setup.data.body.gameText)
           this.$emit('gamedata', setup.data.body.gameSettings)

          }
   


  

      
    }
  }
}
</script>


