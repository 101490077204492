<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame">
         <div class="innerframe">
           <div v-html="$parent.language.TextView.body"></div>
         </div>

         <div class="box clue "  :style="{'background-color':$parent.gameSettings.bruteClueBoxColor}"> 
          <div class="circle circle-middle"><p class="boxclue" >{{ clue0 }}</p></div>
          <div class="circle circle-middle"><p class="boxclue" >{{ clue2 }}</p></div>
          <div class="circle circle-middle"><p class="boxclue" >{{ clue1 }}</p></div>
          <div class="circle circle-middle"><p class="boxclue" >{{ clue3 }}</p></div>
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<style>
  @import '../assets/form.css';
</style>

<script>
// import axios from 'axios'

export default {
  name: 'bruteVideo',
  props: {
    info: Object
  },
  async mounted () {


    this.$emit('pauseTimer', 0)

    this.boxcode = Array.from(this.info.boxcode.toString()).map(Number);


//  hide two number in the code and remove the digits
var randNumber = []
while(randNumber.length != this.$parent.gameSettings.bruteGameHiddenClues){
  var randomnumber=Math.ceil(Math.random()*3)
  if(randNumber.indexOf(randomnumber) === -1){
    randNumber.push(randomnumber)
    this.boxcode[randomnumber] = "?"
  }  
}

//add the new code to the box clues.
this.clue0 = this.boxcode[0];
this.clue1 = this.boxcode[1];
this.clue2 = this.boxcode[2];
this.clue3 = this.boxcode[3];

},
data () {
 return {
  boxcode: "",
  clue0: 0,
  clue1: 0,
  clue2: 0,
  clue3: 0
}
},
// methods: {
//   GameMove() {
//    // axios
//    //      //  .post('http://192.168.1.224:3000/updatestage', {"stage": 3})
//    //    }
//     }
}
</script>


